import { isBrowser } from '../utils'

const Uniformat = () => {
  return (
    isBrowser() &&
    window.location.replace(
      'https://docs.sgnl.pro/s/f/b7fcd067-5b5b-4b08-b220-474d63fbb813'
    )
  )
}

export default Uniformat
